import { useTranslation } from "react-i18next";
import { useAllKategorienFragen } from "../../../queries/wpKategorienFragen/useAllKategorienFragen";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import RVOFaqTree from "../../03-organisms/RVOFaqTree/RVOFaqTree";
import RVOGenericHeroSection from "../../03-organisms/RVOGenericHeroSection/RVOGenericHeroSection";

const RVOFaqTemplate = (): JSX.Element => {
  const { t } = useTranslation();

  const kategorieFragen = useAllKategorienFragen();
  return (
    <>
      <RVOGenericHeroSection title={t("basics.headline")} />

      <RVOContainer paddingBottom={true}>
        {kategorieFragen !== undefined && (
          <RVOFaqTree faqCategory={kategorieFragen} headingLevel={2} />
        )}
      </RVOContainer>
    </>
  );
};

export default RVOFaqTemplate;
