import { graphql, useStaticQuery } from "gatsby";
import { FaqCategory } from "../../components/03-organisms/RVOFaqTree/RVOFaqTreeInterfaces";
import { sanitizeFaqCategories } from "../../helpers/clientSide/sanitizeFaqCategories";

export const useAllKategorienFragen = (): FaqCategory | undefined => {
  const kategorienFragen =
    useStaticQuery<GatsbyTypes.WpKategorieFragenQuery>(graphql`
      query WpKategorieFragen {
        allWpKategorieFragen {
          nodes {
            ...WpKategorieFragenFields
          }
        }
      }
    `);
  const parsedCategories = parseChildCaterogriesInParentCategory(
    null,
    kategorienFragen.allWpKategorieFragen.nodes.concat()
  );

  const parsedCategoriesValue = Object.values(parsedCategories)[0]
    ? Object.values(parsedCategories)[0]
    : undefined;

  const sanitizedCategories =
    parsedCategoriesValue && sanitizeFaqCategories(parsedCategoriesValue);

  return sanitizedCategories;
};

function parseChildCaterogriesInParentCategory(
  parentId: null | string,
  categories: GatsbyTypes.WpKategorieFragenFieldsFragment[]
) {
  const parsedCategories: Record<
    string,
    GatsbyTypes.WpKategorieFragenFieldsFragment
  > = {};
  categories.forEach((category) => {
    if (category.parentId === parentId) {
      const children = parseChildCaterogriesInParentCategory(
        category.id,
        categories
      );
      const parsedChildren =
        Object.keys(children).length === 0 ? {} : { children: children };
      parsedCategories[category.id] = Object.assign(parsedChildren, category);
    }
  });

  return parsedCategories;
}
