import { Box, Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import { Link } from "gatsby-material-ui-components/lib/link";
import slugify from "slugify";
import { basicsPaths } from "../../../helpers/general/frontendUrlPaths";
import { RVOFaqTreeProps } from "./RVOFaqTreeInterfaces";
import { useRVOFaqTreeStyles } from "./RVOFaqTreeStyles";

const RVOFaqTree = (props: RVOFaqTreeProps): JSX.Element => {
  const { faqCategory, headingLevel } = props;
  const heading = `h${headingLevel}` as Variant;
  const { childCategories } = faqCategory;
  const hasQuestions = faqCategory.questions.length > 0;
  const hasChildCategories = faqCategory.childCategories.length > 0;

  const classes = useRVOFaqTreeStyles({ hasQuestions });

  return (
    <>
      {(hasQuestions || hasChildCategories) && (
        <Box className={classes.wrapper}>
          <Typography id={slugify(faqCategory.name)} variant={heading}>
            {faqCategory.name}
          </Typography>

          {faqCategory.questions.map((question) => {
            return (
              <Box key={question.id}>
                <Link
                  to={`${basicsPaths}/${question.slug}`}
                  className={classes.link}
                >
                  {question?.questionFields?.question}
                </Link>
              </Box>
            );
          })}
        </Box>
      )}

      {childCategories.map((child) => {
        return (
          <RVOFaqTree
            key={child.id}
            faqCategory={child}
            headingLevel={headingLevel + 1}
          />
        );
      })}
    </>
  );
};

export default RVOFaqTree;
