import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { allNestedHeadlinesSelector } from "../../../helpers/styling/allHeadlines";
import { RVOFaqTreeStylesProps } from "./RVOFaqTreeInterfaces";

export const useRVOFaqTreeStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: ({ hasQuestions }: RVOFaqTreeStylesProps) => ({
      paddingBottom: hasQuestions ? theme.spacing(2) : undefined,
      [allNestedHeadlinesSelector]: {
        marginBottom: theme.spacing(1),
      },
      // Smol trick to offset the header height when achor-scrolling
      "& .MuiTypography-h3:before": {
        content: "''",
        display: "block",
        height: "100px",
        marginTop: "-100px",
        visibility: "hidden",
      },
    }),

    link: {
      display: "block",
    },
  })
);
