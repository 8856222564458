import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { Template } from "../../../config/wordPressConfig";
import { usePageForTemplate } from "../../queries/wpPage/usePageForTemplate";
import RVORegisterTodayCtaSection from "../03-organisms/RVORegisterTodayCtaSection/RVORegisterTodayCtaSection";
import RVOFaqTemplate from "../04-templates/RVOFaqTemplate/RVOFaqTemplate";

export const FaqPage = (): JSX.Element => {
  const faqPage = usePageForTemplate(Template.Faq);
  return (
    <>
      <Seo post={faqPage} />

      <RVOFaqTemplate />

      <RVORegisterTodayCtaSection />
    </>
  );
};

export default FaqPage;
