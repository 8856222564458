import { filterNoUndefinedNoNull } from "@nvon/baseline";
import {
  FaqCategory,
  FaqQuestion,
} from "../../components/03-organisms/RVOFaqTree/RVOFaqTreeInterfaces";

export const sanitizeFaqCategories = (
  parsedCategories: GatsbyTypes.WpKategorieFragenFieldsFragment
): FaqCategory => {
  const { id, name, slug, questions, children } =
    parsedCategories as unknown as GatsbyTypes.WpKategorieFragen;

  const updatedQuestions: FaqQuestion[] =
    questions?.nodes !== undefined
      ? questions.nodes
          .map((question) => {
            if (
              !question ||
              !question.slug ||
              !question.title ||
              !question.id
            ) {
              return undefined;
            }
            return {
              id: question.id,
              slug: question.slug,
              title: question.title,
              questionFields: {
                question: question.questionFields?.question || "",
                answer: question.questionFields?.answer || "",
              },
            };
          })
          .filter(filterNoUndefinedNoNull)
      : [];

  const faqCategory: FaqCategory = {
    id,
    name: name || "",
    slug: slug || "",
    questions: updatedQuestions,
    childCategories: [],
  };

  if (children) {
    Object.values(children).forEach((child) => {
      faqCategory.childCategories.push(
        sanitizeFaqCategories(
          child as unknown as GatsbyTypes.WpKategorieFragenFieldsFragment
        )
      );
    });
  }

  return faqCategory;
};
